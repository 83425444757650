//import { Component } from '@angular/core';

//@Component({
//  selector: 'app-root',
//  templateUrl: './app.component.html',
//  styleUrls: ['./app.component.css']
//})
//export class AppComponent {
//  title = 'app-pwp-editor-add-image';
//}


import { Component, Inject, LOCALE_ID } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { DialogComponent } from '../../../app-pwp/src/app/dialog/dialog.component';
import { application } from '../../../app-pwp/src/app/core/globals';
import { IGlobal } from '../../../app-pwp/src/app/core/interfaces/IGlobal';
import { IState } from '../../../app-pwp/src/app/core/interfaces/IState';
import { GlobalSettingsService } from '../../../app-pwp/src/app/core/services/data/globalsettings.service';
import { HttpErrorService } from '../../../app-pwp/src/app/core/services/common/httperror.service';
import { ISystemTextExtended } from '../../../app-pwp/src/app/core/interfaces/ISystemTextExtended';
import { IReturnState } from '../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IGlobalSettings } from '../../../app-pwp/src/app/core/interfaces/IGlobalSettings';
import { DialogService } from '../../../app-pwp/src/app/core/services/common/dialog.service';


@Component({
  selector: 'app-img-edit-root',
  templateUrl: './app-img-edit.component.html',
  styleUrls: ['./app-img-edit.component.css']
})
export class AppImgEditComponent {
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private http401Handled: boolean = false;

  private readonly MODE_LOGIN = 0;
  private readonly MODE_LOADING = 1;
  private readonly MODE_APPLICATION = 2;

  /*
   * 0 - login
   * 1 - loading
   * 2 - application
   */
  public mode: number = 0;
  private isAuthenticated: boolean = true;
  public isLocal: boolean = false;
  public isInit: boolean = false;
  public isSessionLost: boolean = false;

  private application: IGlobal = application;

  private title = 'app';
  private state: IState;

  private loadingIsPendingTexts: boolean = false;
  private loadingIsPendingState: boolean = false;
  private loadingIsPendingGlSet: boolean = false;

  constructor(private http: HttpClient,
    private httpErrorService: HttpErrorService,
    @Inject('BASE_URL') private baseUrl: string,
    @Inject(LOCALE_ID) private locale: string,
    private dialog: MatDialog,
    private globalSettingsService: GlobalSettingsService,
    private dialogService: DialogService) {
    this.getRoleAccessObjects();
    this.doStateCheck();

    application.setCulture(this.locale);
  };

  private ngOnInit() {
    this.httpErrorService.http403.asObservable().subscribe(values => {
      if (values === true) {
        this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
        this.dialogGeneric.componentInstance.options.actionOk = true;
        this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
        this.dialogGeneric.componentInstance.options.message = application.getRawText('common.http403.msg');
      }
    });

    this.httpErrorService.http401.asObservable().subscribe(values => {
      if (values === true) {
        if (this.http401Handled === false) {
          this.isSessionLost = true;
          this.http401Handled = true;
          this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
          this.dialogGeneric.componentInstance.options.actionYes = true;
          this.dialogGeneric.componentInstance.options.actionNo = true;
          this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
          this.dialogGeneric.componentInstance.options.message = application.getRawText('common.http401.msg');
          this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
            this.http401Handled = false;
            if (_action.action === 'no') {

            }
            else {

              this.http
                .get<IState>(this.baseUrl + 'api/pwp/security/logout')
                .subscribe(result => {
                  this.onStateChangedHandler(result);
                  application.technicalRoleNames = null;
                }, error => console.error(error));
            }
          });
        }
      }
    });
  }

  private getTexts() {
    this.loadingIsPendingTexts = true;
    this.setMode();

    this.http
      .get<IReturnState>(this.baseUrl + 'api/pwp/system/texts/' + this.locale)
      .subscribe(result => {
        application.setTexts(result.data as ISystemTextExtended[]);

        this.loadingIsPendingTexts = false;
        this.setMode();

      }, error => console.error(error));
  };

  private getGlobalSettings() {
    this.loadingIsPendingGlSet = true;
    this.setMode();

    this.globalSettingsService.GetSettings().subscribe((_value: IGlobalSettings) => {
      application.globalSettings = _value;

      this.loadingIsPendingGlSet = false;
      this.setMode();
    });
  };

  private getRoleAccessObjects() {
    //this.http
    //    .get<RoleAccessObjects[]>(this.baseUrl + 'api/settings/roleaccessobjects')
    //    .subscribe(result =>
    //    {
    //        this.application.setRoleAccessObjects(result);
    //    }, error => console.error(error));
  };

  private doStateCheck() {
    this.loadingIsPendingState = true;
    this.setMode();

    this.http
      .get<IState>(this.baseUrl + 'api/pwp/security/state')
      .subscribe(result => {
        this.application.version = result.version;
        this.state = result;
        this.isLocal = this.state.isLocal === true;
        this.isInit = this.state.isInit === true;

        this.isAuthenticated = this.state.value === true;

        if (this.isAuthenticated === true) {
          this.loadData();
        }

        if (this.state.value === true) {
          application.technicalRoleNames = this.state.technicalRoleNames;
          application.user = this.state.userName;
          application.isAdmin = this.state.isAdmin;
        }

        this.loadingIsPendingState = false;
        this.setMode();

      }, error => console.error(error));
  };

  private onStateChangedHandler(event: IState) {
    this.state = event;
    this.isAuthenticated =
      ((this.state.action === 1 || this.state.action === 4) &&
        this.state.value === true);
    this.isSessionLost = this.isAuthenticated === false;

    if (this.state.action === 1 && this.isAuthenticated === true) {
      this.loadData();
    }

    this.setMode();
  };

  private loadData() {
    this.getTexts();
    this.getGlobalSettings();
  };

  private setMode() {
    if (this.loadingIsPendingTexts || this.loadingIsPendingState || this.loadingIsPendingGlSet) {
      this.mode = this.MODE_LOADING;
    }
    else if (this.isAuthenticated === true) {
      this.mode = this.MODE_APPLICATION;
    }
    else {
      this.mode = this.MODE_LOGIN;
    }
  };
}
