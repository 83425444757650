import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { ISettings } from '../core/interfaces/ISettings';
import { ISystemLanguage } from '../../../../app-pwp/src/app/core/interfaces/ISystemLanguage';
import { ILog } from '../../../../app-pwp/src/app/core/interfaces/ILog';
import Settings from '../core/models/Settings';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { WeekDaysService } from '../../../../app-pwp/src/app/core/services/common/weekdays.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { SystemService } from '../../../../app-pwp/src/app/core/services/data/system.service';
import { SettingsService } from '../core/services/data/settings.service';
import SystemLanguage from '../../../../app-pwp/src/app/core/models/SystemLanguage';
import { IMediaSettingsCollection } from '../core/interfaces/IMediaSettingsCollection';
import { IImageUploadResizeOption } from '../../../../app-pwp/src/app/core/interfaces/IImageUploadResizeOption';
import { DialogComponent } from '../../../../app-pwp-evision5-webplayer/src/app/dialog/dialog.component';
import { DialogService } from '../../../../app-pwp/src/app/core/services/common/dialog.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private settingsFromServer: IMediaSettingsCollection = null;

  public settings: ISettings = new Settings();
  public languages: ISystemLanguage[] = [];
  public imageUploadResizeOptions: IImageUploadResizeOption[] = [];
  public logs: ILog[] = [];

  private dialogGeneric: MatDialogRef<DialogComponent> = null;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public logsDisplayedColumns: string[] = ['level', 'createDate', 'name', 'text'];

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;

  public textStandardUrl: string = application.getRawText('settings.panel.primary.form.standardurl.title');
  public textFallbackCulture: string = application.getRawText('settings.panel.primary.form.fallbackculture.title');
  public textTabSettings: string = application.getRawText('settings.panel.primary.title');
  public textTabLog: string = application.getRawText('settings.panel.log.title');
  public textTabApiKeys: string = application.getRawText('settings.panel.apikeys.title');
  public textTabSlack: string = application.getRawText('settings.panel.slack.title');
  public textActivityDuration: string = application.getRawText('settings.panel.primary.form.defaultactivitydurationsec.title');
  public textCrossFading: string = application.getRawText('settings.panel.primary.form.crossfadingdurationms.title');
  public textResizeUploadedImage: string = application.getRawText('settings.panel.primary.form.resizeuploadedimage.title');

  //private dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandlerSettings: KeyHandler;

  public activeTabIndex: number = 0;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private weekDays: WeekDaysService,
    private dateTime: DateTimeService,
    private route: ActivatedRoute,
    private uiKeyHandleService: UiKeyHandleService,
    private systemService: SystemService,
    private settingsService: SettingsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dialogService: DialogService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.settingsService);

    this.keyHandlerSettings = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandlerSettings.OnSave.subscribe(() => this.save());
  };

  ngOnInit() {
    if (this.initiated) {
      return;
    }

    this.init();
  };

  private init() {
    if (!this.security.CheckForRoles(true, ['contentmanager'])) {
      return;
    }

    this.settings = new Settings();
    this.settings.fallbackCulture = "default";

    this.loadSettings();

    this.initiated = true;
  };

  private loadLogs() {
    this.systemService.GetLogs(100, ['trace', 'debug', 'error', 'info', 'warn', 'fatal'])
      .subscribe((_result: ILog[]) => {
        this.logs = _result;
      });
  };

  private loadSettings() {

    this.imageUploadResizeOptions =
      [{ name: application.getRawText('settings.panel.resize.noscale'), systemname: "" },
      { name: application.getRawText('settings.panel.resize.4k'), systemname: "4K" },
      { name: application.getRawText('settings.panel.resize.fullhd'), systemname: "FullHD" }];

    this.systemService.GetLanguages()
      .subscribe((_result) => {
        this.languages = _result as SystemLanguage[];
      });

    this.settingsService.GetSettings()
      .subscribe((_result: IMediaSettingsCollection) => {
        this.settingsFromServer = _result;

        this.settings.standardUrl = _result.standardUrlValue;
        this.settings.fallbackCulture = _result.fallbackCultureValue;
        this.settings.browserLanguageDetectionEnabled = _result.browserLanguageDetectionEnabledValue;
        this.settings.crossFadingDurationMs = _result.crossFadingDurationMsValue;
        this.settings.defaultActivityDurationSec = _result.defaultActivityDurationSecValue;
        this.settings.silentVideoPlayback = _result.silentVideoPlaybackValue;
        this.settings.imageUploadResizeOption = _result.imageUploadResizeOptionValue;
        this.settings.sessionStayAlive = _result.sessionStayAliveValue;
      });
  };

  public selectedTabChangeHandler(_event: MatTabChangeEvent) {
    this.activeTabIndex = _event.index;

    if (this.activeTabIndex === 0) {
      this.loadSettings();
    }
    else if (this.activeTabIndex === 2) {
      this.loadLogs();
    }

  };

  public save() {
    this.settingsService.SaveSettings(this.settings)
      .subscribe((_result) => {
        if (_result.success) {
          if (this.settingsFromServer.sessionStayAliveValue !== this.settings.sessionStayAlive) {
            this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
            this.dialogGeneric.componentInstance.options.actionYes = true;
            this.dialogGeneric.componentInstance.options.actionNo = true;
            this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
            this.dialogGeneric.componentInstance.options.message = application.getRawText('usersettings.single.saved.sessionStayAlive.reload.question');
            this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
              if (_action.action === 'yes') {
                location.reload();
              }
            });
          }

          this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });
  };
}
