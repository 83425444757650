import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { IResetPassword } from '../../core/interfaces/IResetPassword';
import ResetPassword from '../../core/models/ResetPassword';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { DialogService } from '../../../../../app-pwp/src/app/core/services/common/dialog.service';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})

export class SetPasswordComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;

  // this flag is required to handle the manual init via init().
  // Avoids resetting/overwriting the id from parameters.
  private initiated: boolean = false;
  public item: IResetPassword = new ResetPassword();

  public textCurrent: string = application.getRawText('usersettings.setpassword.form.current.title');
  public textNew: string = application.getRawText('usersettings.setpassword.form.new.title');
  public textRetyped: string = application.getRawText('usersettings.setpassword.form.retyped.title');

  public keyHandler: KeyHandler;

  //dialogGeneric: MatDialogRef<DialogComponent>;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    //private security: SecurityService,
    private route: ActivatedRoute,
    private uiKeyHandleService: UiKeyHandleService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dialogService: DialogService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
    this.keyHandler.OnCancel.subscribe(() => this.triggerOnClose());
  };

  ngOnInit() {
    if (this.initiated) {
      return;
    }

    this.initiated = true;
  };

  private init(_mediaJobID: string) {
    this.initiated = true;
  };

  public save() {
    this.http.post<IReturnState>(application.buildApi(this.baseUrl, 'security/changepassword'), this.item)
      .subscribe((_result) => {
        if (_result.success) {
          this.triggerOnClose();
        }
        else if (_result.stateID === 96) {
          this.showError('usersettings.setpassword.error.complexityfailed.msg');
        }
        else if (_result.stateID === 98) {
          this.showError('usersettings.setpassword.error.retypediswrong.msg');
        }
        else if (_result.stateID === 97) {
          this.showError('usersettings.setpassword.error.currentiswrong.msg');
        }
        else {
          this.showError('usersettings.setpassword.failed.msg');
        }
      });

  };

  public showError(_msg: string) {
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionOk = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText(_msg);
  }

  public triggerOnClose() {
    this.onClose.emit();
  };
};

