import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogComponent } from './../dialog/dialog.component';
import { RoleSingleComponent } from '../role-single/role-single.component';

import { application } from './../core/globals';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { RolesService } from './../core/services/data/roles.service';
import { IRole } from '../core/interfaces/IRole';
import { IReturnState } from '../core/interfaces/IReturnState';
import { DialogService } from '../core/services/common/dialog.service';

@Component({
  selector: 'app-roles-summary',
  templateUrl: './roles-summary.component.html',
  styleUrls: ['./roles-summary.component.css']
})

export class RolesSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEdit: MatDialogRef<RoleSingleComponent>;

  private displayColumnsAll: string[] = ['name', 'isAuthor', 'isEditor', 'isContentManager', 'edit'];
  public displayedColumns: string[] = this.displayColumnsAll;

  private roleID: number = null;
  public roles: IRole[] = [];
  public rolesDataSource = new MatTableDataSource(this.roles);

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private rolesService: RolesService,
    private dialogService: DialogService) {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.rolesService);
  };

  private ngOnInit() {
    if (!this.security.CheckForRoles(true, [])) {
      return;
    }

    this.loadRoles();
  };

  private loadRoles() {
    this.rolesService.GetRoles().subscribe((_users) => {
      this.roles = _users;
      this.rolesDataSource = new MatTableDataSource(this.roles);
    });
  };

  public delete(_role: IRole) {
    this.roleID = _role.roleID;
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('pwp.roles.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.rolesService.DeleteRole(_role.roleID).subscribe(_returnState => {
        var result = _returnState as IReturnState;
        if (result.success) {
          this.loadRoles();
          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.roles.summary.deleted.msg'), application.getRawText('common.close.msg'));
        }
        else if (result.stateID === 99) {
          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.roles.summary.delete.error.user-assigned.msg'), application.getRawText('common.close.msg'));
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });

    });
  };

  public edit(_role: IRole) {
    this.roleID = _role?.roleID;
    this.dialogEdit = this.dialog.open(RoleSingleComponent, this.dialogService.getDialogOptionsOneColumn());
    this.dialogEdit.componentInstance.roleID = _role?.roleID;
    this.dialogEdit.componentInstance.initRole();
    this.dialogEdit.componentInstance.onClose.subscribe(() => {
      this.dialogEdit.close();
      this.loadRoles();
    });
  };

}
