import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { application } from './../core/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageSelectorComponent } from '../image-selector/image-selector.component';

import { UiKeyHandleService, KeyHandler } from './../core/services/common/uikeyhandle.service';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { ModulesService } from './../core/services/data/modules.service';
import EventItem from './../core/models/EventItem';
import { IEventItem } from '../core/interfaces/IEventItem';
import { IModule } from '../core/interfaces/IModule';
import { IReturnState } from '../core/interfaces/IReturnState';
import { DialogService } from '../core/services/common/dialog.service';

@Component({
  selector: 'app-eventitem-single',
  templateUrl: './eventitem-single.component.html',
  styleUrls: ['./eventitem-single.component.css']
})

export class EventItemSingleComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private dialogImageSelect: MatDialogRef<ImageSelectorComponent>;
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public eventItemID: number = null;
  public eventItem: IEventItem = new EventItem();
  public startTime: string = '00:00';
  public expireTime: string = '00:00';

  public modules: IModule[] = [];

  private initiated: boolean = false;

  public textTitle: string = application.getRawText('pwp.evenitem.single.form.title.title');
  public textDescription: string = application.getRawText('pwp.evenitem.single.form.description.title');
  public textPlace: string = application.getRawText('pwp.evenitem.single.form.place.title');
  public textStartDate: string = application.getRawText('pwp.evenitem.single.form.startdate.title');
  public textExpireDate: string = application.getRawText('pwp.evenitem.single.form.expiredate.title');
  public textModule: string = application.getRawText('pwp.evenitem.single.form.module.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dateTimeService: DateTimeService,
    private modulesService: ModulesService,
    private dialogService: DialogService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());

    this.eventItem.startDate = this.dateTimeService.Now();
    this.eventItem.expireDate = this.dateTimeService.Now();
  };

  ngOnInit() {
    if (this.initiated) {
      return;
    }

    var tmpID = parseInt(this.route.snapshot.paramMap.get('id'));
    this.eventItemID = tmpID > 0 ? tmpID : null;
    this.initEventItem();
  };

  public initEventItem() {
    if (this.initiated) {
      return;
    }

    this.loadModules();

    if (!(this.eventItemID > 0)) {
      this.eventItemID = null;
      this.eventItem = new EventItem();
      this.eventItem.startDate = this.dateTimeService.Now();
      this.eventItem.expireDate = this.dateTimeService.Now();

      this.startTime = this.dateTimeService.GetTime(this.eventItem.startDate);
      this.expireTime = this.dateTimeService.GetTime(this.eventItem.expireDate);
    }
    else {
      this.loadEventItem();
    }

    this.initiated = true;
  };

  private loadEventItem() {
    this.http.get<IReturnState>(application.buildApi(this.baseUrl, 'eventitem/' + this.eventItemID))
      .subscribe(result => {
        this.eventItem = result.data as IEventItem;

        var tmpStartDate = this.dateTimeService.EnsureUTC(this.eventItem.startDate);
        this.startTime = this.dateTimeService.GetTime(tmpStartDate, true);
        this.eventItem.startDate = this.dateTimeService.TimeToLunch(tmpStartDate);

        var tmpExpireDate = this.dateTimeService.EnsureUTC(this.eventItem.expireDate);
        this.expireTime = this.dateTimeService.GetTime(tmpExpireDate, true);
        this.eventItem.expireDate = this.dateTimeService.TimeToLunch(tmpExpireDate);

      }, error => console.error(error));
  };

  private loadModules() {
    this.modulesService.GetEventItems().subscribe((_modules) => {
      this.modules = _modules;
      if (!(this.eventItemID > 0) && this.modules.length > 0) {
        this.eventItem.moduleID = this.modules[0].moduleID;
      }
    });
  };

  public startFromNow() {
    var tmp = this.dateTimeService.Now();
    this.startTime = this.dateTimeService.GetTime(tmp, true);
    this.eventItem.startDate = this.dateTimeService.TimeToLunch(tmp);
  };

  public endToNow() {
    var tmp = this.dateTimeService.Now();
    this.expireTime = this.dateTimeService.GetTime(tmp, true);
    this.eventItem.expireDate = this.dateTimeService.TimeToLunch(tmp);
  };

  public save() {
    var url = this.eventItemID === null || this.eventItemID === 0
      ? application.buildApi(this.baseUrl, 'eventitem')
      : application.buildApi(this.baseUrl, 'eventitem/' + this.eventItemID);

    this.eventItem.startDate = this.dateTimeService.SetTimeToDate(this.startTime, this.eventItem.startDate);
    this.eventItem.expireDate = this.dateTimeService.SetTimeToDate(this.expireTime, this.eventItem.expireDate);

    this.http
      .post<IReturnState>(url, this.eventItem)
      .subscribe(
        result => {
          if (result.success) {
            this.eventItem = result.data as IEventItem;
            this.eventItemID = this.eventItem.itemID;
            this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));

            this.close();
          }
          else {
            this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
          }
        },
        error => console.error(error));
  };

  public openSelectImage() {
    this.dialogImageSelect = this.dialog.open(ImageSelectorComponent, this.dialogService.getDialogOptionsOneColumnAndAHalf());
    this.dialogImageSelect.componentInstance.Init();
    this.dialogImageSelect.componentInstance.ModuleID = this.eventItem.moduleID;
    this.dialogImageSelect.componentInstance.OnClose.subscribe(() => {
      if (this.dialogImageSelect.componentInstance.ImageItem !== null) {
        this.eventItem.imageID = this.dialogImageSelect.componentInstance.ImageItem.id;
      }

      if (this.dialogImageSelect.componentInstance.ThumbItem !== null) {
        this.eventItem.thumbID = this.dialogImageSelect.componentInstance.ThumbItem.id;
      }

      this.dialogImageSelect.close();
    });
  };

  public close() {
    this.onClose.emit(this.eventItem.itemID);
  };

}
