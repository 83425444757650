import { Component, Inject, ViewChild, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MediaListSingleComponent } from '../medialist-single/medialist-single.component';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { IMediaListExtended } from '../core/interfaces/IMediaListExtended';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { evision5 } from '../core/globals';
import { IGlobal } from '../../../../app-pwp/src/app/core/interfaces/IGlobal';
import { FileExplorerComponent } from '../fileexplorer/fileexplorer.component';
import { DialogService } from '../../../../app-pwp/src/app/core/services/common/dialog.service';

@Component({
  selector: 'app-medialists-summary',
  templateUrl: './medialists-summary.component.html',
  styleUrls: ['./medialists-summary.component.css'],
  inputs: ['useRunningMode']
})

export class MediaListsSummaryComponent {
  public application: IGlobal = application;

  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private readonly SORT_NAME: string = 'sort';
  private readonly SORT_CREATIONDATE: string = 'creationdate';

  private sortField: string = this.SORT_NAME;
  @ViewChild(MatSort) sort: MatSort;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditMediaList: MatDialogRef<MediaListSingleComponent>;
  private dialogFileExplorer: MatDialogRef<FileExplorerComponent>;

  private displayColumnsAll: string[] = ['linkedList', 'listName', 'preview', 'durationSumFormatted', 'mediaTypes', 'createDate', 'edit'];
  private displayColumnsDashboard: string[] = ['linkedList', 'listName', 'preview', 'durationSumFormatted', 'mediaTypes', 'createDate'];
  public displayedColumns: string[] = this.displayColumnsAll;
  //'elementCount', 

  public textSearchTitle: string = application.getRawText('medialists.summary.top.search.title');
  public textSortSelection: string = application.getRawText('medialists.summary.top.sort.title');

  private mediaListID: string = null;
  public mediaLists: IMediaListExtended[] = [];
  public mediaListDataSource = new MatTableDataSource(this.mediaLists);

  private search: string = null;

  public readonly MODE_NORMAL = 'normal';
  public readonly MODE_DASHBOARD = 'dashboard';

  @Input() runningMode: string = this.MODE_NORMAL;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dialogService: DialogService) {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
  };

  private ngOnInit() {
    //if (!this.security.CheckForRoles(true, ['contentmanager', 'author']))
    //{
    //  return;
    //}

    this.displayedColumns = this.runningMode === this.MODE_DASHBOARD
      ? this.displayColumnsDashboard
      : this.displayColumnsAll;

    this.loadMediaLists();
  };

  private sortChangeHandler() {
    //var field = this.sortField == this.SORT_CREATIONDATE
    //  ? { name: 'createDate', order: 'desc' }
    //  : { name: 'listName', order: 'asc' };

    //this.mediaListDataSource.sort.sort(<MatSortable>({ id: field.name, start: field.order }));
  };

  public delete(_mediaListID: string) {
    this.mediaListID = _mediaListID;

    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('medialists.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.http
        .delete<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + _mediaListID), {})
        .subscribe(
          _result => {
            var result = _result as IReturnState;
            if (result.success) {
              this.mediaListID = null;

              this.loadMediaLists();
              this.snackBarRef = this.snackBar.open(application.getRawText('medialists.summary.deleted.msg'), application.getRawText('common.close.msg'));
            }
            else {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    });
  };

  public getMediaUrlPath(_mediaActivityUrl: string) {
    return evision5.buildApi(this.baseUrl, 'files/thumbnail?mediaurl=' + encodeURIComponent(_mediaActivityUrl));
  };

  private loadMediaLists() {
    var query = this.search != null
      ? encodeURIComponent(this.search)
      : '';

    var url = this.runningMode == this.MODE_NORMAL
      ? evision5.buildApi(this.baseUrl, 'medialists/?query=' + query)
      : evision5.buildApi(this.baseUrl, 'medialists/latest/?query=' + query)

    this.http.get<IReturnState>(url)
      .subscribe(result => {
        this.mediaLists = result.data as IMediaListExtended[];
        this.mediaLists.forEach((_ml) => _ml.createDate = this.dateTimeService.EnsureUTC(_ml.createDate));

        this.mediaListDataSource = new MatTableDataSource(this.mediaLists);
        this.mediaListDataSource.sort = this.sort;

        this.sortField = this.SORT_NAME;
        this.sortChangeHandler();

      }, error => console.error(error));
  };

  public editMediaList(_mediaListID: string) {
    this.mediaListID = _mediaListID;

    this.dialogEditMediaList = this.dialog.open(MediaListSingleComponent, this.dialogService.getDialogOptionsOneColumnAndAHalf());
    this.dialogEditMediaList.componentInstance.mediaListID = _mediaListID;
    this.dialogEditMediaList.componentInstance.initMediaList();
    this.dialogEditMediaList.componentInstance.onClose.subscribe((_value) => {
      this.dialogEditMediaList.close();
      this.loadMediaLists();
      if (_mediaListID == 'new') {
        this.router.navigate(['/medialists', _value, 'mediaactivities']);
      }
    });
  };

  public onSearchHandler() {
    this.loadMediaLists();
  };


}
