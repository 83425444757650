import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogComponent } from './../dialog/dialog.component';
import { EventItemSingleComponent } from '../eventitem-single/eventitem-single.component';

import { application } from './../core/globals';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { ModulesService } from './../core/services/data/modules.service';
import { IEventItemExtended } from '../core/interfaces/IEventItemExtended';
import { IModule } from '../core/interfaces/IModule';
import { IReturnState } from '../core/interfaces/IReturnState';
import { DialogService } from '../core/services/common/dialog.service';

@Component({
  selector: 'app-eventitems-summary',
  templateUrl: './eventitems-summary.component.html',
  styleUrls: ['./eventitems-summary.component.css']
})

export class EventItemsSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditEventItem: MatDialogRef<EventItemSingleComponent>;

  private displayColumnsAll: string[] = ['title', 'module', 'description', 'startDate', 'edit'];
  public displayedColumns: string[] = this.displayColumnsAll;

  public modules: IModule[] = [];

  public filterModuleID: number = null;

  public textFilterModule: string = application.getRawText('pwp.evenitems.summary.filter.eventitemmodule.title');

  private itemID: number = null;
  public eventItems: IEventItemExtended[] = [];
  public eventItemsDataSource = new MatTableDataSource(this.eventItems);

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private modulesService: ModulesService,
    private dialogService: DialogService) {
    this.security.CheckForFeature('events');

    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
  };

  ngOnInit() {
    this.loadEventItems();
    this.loadModules();
  };

  private loadModules() {
    this.modulesService.GetEventItemsForSelection().subscribe((_modules) => {
      this.modules = _modules;
    });
  };

  public onModuleFilterChanged() {
    this.loadEventItems();
  };

  public delete(_eventItem: IEventItemExtended) {
    this.itemID = _eventItem.itemID
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('pwp.evenitems.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.http
        .delete<IReturnState>(application.buildApi(this.baseUrl, 'eventitem/' + _eventItem.itemID), {})
        .subscribe(
          _result => {
            var result = _result as IReturnState;
            if (result.success) {
              this.loadEventItems();
              this.snackBarRef = this.snackBar.open(application.getRawText('medialists.summary.deleted.msg'), application.getRawText('common.close.msg'));
            }
            else {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    });
  };

  private loadEventItems() {
    var url = this.filterModuleID != null
      ? application.buildApi(this.baseUrl, 'eventitems/module/' + this.filterModuleID)
      : application.buildApi(this.baseUrl, 'eventitems');

    this.http.get<IReturnState>(url)
      .subscribe(result => {
        this.eventItems = result.data as IEventItemExtended[];
        this.eventItems.forEach((_ml) => {
          if (!_ml) {
            return;
          }
          _ml.startDate = this.dateTimeService.EnsureUTC(_ml.startDate);
          _ml.expireDate = this.dateTimeService.EnsureUTC(_ml.expireDate);
        });

        this.eventItemsDataSource = new MatTableDataSource(this.eventItems);

      }, error => console.error(error));
  };

  public editEventItem(_eventItem: IEventItemExtended) {
    this.itemID = _eventItem?.itemID
    this.dialogEditEventItem = this.dialog.open(EventItemSingleComponent, this.dialogService.getDialogOptions('450px', '850px'));
    this.dialogEditEventItem.componentInstance.eventItemID = _eventItem?.itemID;
    this.dialogEditEventItem.componentInstance.initEventItem();
    this.dialogEditEventItem.componentInstance.onClose.subscribe(() => {
      this.dialogEditEventItem.close();
      this.loadEventItems();
    });
  };

}
