import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { ApiKeySingleComponent } from '../apikey-single/apikey-single.component';

import { application } from './../core/globals';
import { SecurityService } from './../core/services/common/security.service';
import { DateTimeService } from './../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from './../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from './../core/services/busyindicator/busyindicatorfactory.service';
import { ApiKeysService } from './../core/services/data/apikeys.service';
import { UsersService } from './../core/services/data/users.service';
import { IUserToEdit } from '../core/interfaces/IUserToEdit';
import { IReturnState } from '../core/interfaces/IReturnState';
import { IGlobal } from '../core/interfaces/IGlobal';
import { IUserApiKey } from '../core/interfaces/IUserApiKey';
import { IApiKey } from '../core/interfaces/IApiKey';
import { DialogService } from '../core/services/common/dialog.service';

@Component({
  selector: 'app-apikeys-summary',
  templateUrl: './apikeys-summary.component.html',
  styleUrls: ['./apikeys-summary.component.css']
})

export class ApiKeysSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private application: IGlobal = application;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditApiKey: MatDialogRef<ApiKeySingleComponent>;

  private displayColumnsAll: string[] = ['name', 'creationDate', 'key', 'disabled', 'expirationDate', 'userName', 'edit'];
  public displayedColumns: string[] = this.displayColumnsAll;

  public textUserFilter: string = application.getRawText('pwp.apikeys.summary.filter.user.new.title');

  public filterUserID: number = null;

  public apiKey_ID: number = null;
  public users: IUserToEdit[] = [];
  public apiKeys: IUserApiKey[] = [];
  public apiKeysDataSource = new MatTableDataSource(this.apiKeys);

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private usersService: UsersService,
    private apiKeysService: ApiKeysService,
    private dialogService: DialogService) {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.apiKeysService);
    this.busyIndicator.Register(this.usersService);
  };

  private ngOnInit() {
    if (!this.security.CheckForRoles(true, [])) {
      return;
    }

    this.loadUsers();
    this.loadApiKeys();
  };

  private loadUsers() {
    this.usersService.GetUsersForSelection().subscribe((_users: IUserToEdit[]) => {
      this.users = _users;
    });
  };

  private loadApiKeys() {
    if (this.filterUserID > 0) {
      this.apiKeysService.GetApiKeysByUser(this.filterUserID).subscribe((_apiKeys) => {
        this.apiKeys = _apiKeys;
        this.apiKeysDataSource = new MatTableDataSource(this.apiKeys);
      });
    }
    else {
      this.apiKeysService.GetApiKeys().subscribe((_apiKeys) => {
        this.apiKeys = _apiKeys;
        this.apiKeysDataSource = new MatTableDataSource(this.apiKeys);
      });
    }
  };

  private watchApiKey(_apiKey: IUserApiKey) {
    this.apiKeysService.GetApiKey(_apiKey.apiKey_ID).subscribe((_returnState: IReturnState) => {
      var apiKey = _returnState.data as IApiKey;
      this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
      this.dialogGeneric.componentInstance.options.actionOk = true;
      this.dialogGeneric.componentInstance.options.title = application.getRawText('pwp.apikeys.summary.dialog.key.title') + ' [' + apiKey.name + ']';
      this.dialogGeneric.componentInstance.options.message = apiKey.key;
    });
  };

  public delete(_apiKey: IUserApiKey) {
    this.apiKey_ID = _apiKey.apiKey_ID;
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('pwp.apikeys.summary.delete.msg.title');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.apiKeysService.DeleteApiKey(_apiKey.apiKey_ID).subscribe(_returnState => {
        var result = _returnState as IReturnState;
        if (result.success) {
          this.loadApiKeys();
          this.snackBarRef = this.snackBar.open(application.getRawText('pwp.apikeys.summary.deleted.msg.title'), application.getRawText('common.close.msg'));
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });

    });
  };

  public edit(_apiKey: IUserApiKey) {
    if (_apiKey) {
      this.apiKey_ID = _apiKey.apiKey_ID;
    }

    this.dialogEditApiKey = this.dialog.open(ApiKeySingleComponent, this.dialogService.getDialogOptionsOneColumn());
    this.dialogEditApiKey.componentInstance.apiKeyID = _apiKey?.apiKey_ID;
    this.dialogEditApiKey.componentInstance.initApiKey();
    this.dialogEditApiKey.componentInstance.onClose.subscribe(() => {
      this.dialogEditApiKey.close();
      this.loadApiKeys();
    });
  };

  public onUserFilterChanged() {
    this.loadApiKeys();
  };
}
