import { Component, Inject, Input, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import MediaJob from '../../core/models/MediaJob';
import { IMediaJob } from '../../core/interfaces/IMediaJob';
import { IMediaList } from '../../core/interfaces/IMediaList';
import { IMediaDevice } from '../../core/interfaces/IMediaDevice';
import { IWeekDayItem } from '../../../../../app-pwp/src/app/core/interfaces/IWeekDayItem';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { KeyHandler, UiKeyHandleService } from '../../../../../app-pwp/src/app/core/services/common/uikeyhandle.service';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../../app-pwp/src/app/core/services/common/datetime.service';
import { WeekDaysService } from '../../../../../app-pwp/src/app/core/services/common/weekdays.service';
import { MediaJobsService } from '../../core/services/data/mediajobs.service';
import { evision5 } from '../../core/globals';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { SceneJobsEditSummaryComponent } from '../scene-jobs-edit-summary/scene-jobs-edit-summary.component';
import { UtilsService } from '../../../../../app-pwp/src/app/core/services/common/utils.service';
import { DialogService } from '../../../../../app-pwp/src/app/core/services/common/dialog.service';

@Component({
  selector: 'app-scene-jobs-summary',
  templateUrl: './scene-jobs-summary.component.html',
  styleUrls: ['./scene-jobs-summary.component.css']
})

export class SceneJobsSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditMediaJob: MatDialogRef<SceneJobsEditSummaryComponent>;

  private mediaJobID: string = null;
  private sceneID: string = null;
  public scene: IMediaJob = null;

  private emptyGuid: string = null;

  private displayedColumnsAll: string[] = ['preview', 'name', 'device', 'medialist', 'edit'];
  public displayedColumns: string[] = this.displayedColumnsAll;

  private devicesDic: object = {};
  private devices: IMediaDevice[] = null;
  private groups: IMediaDevice[] = null;

  // Array with jobs received from server.
  public mediaJobs: IMediaJob[];

  public textDeviceFilter: string = application.getRawText('mediajobs.summary.top.devices.title');
  public textDeviceDevice: string = application.getRawText('mediajobs.summary.top.devices.device.title');
  public textDeviceGroup: string = application.getRawText('mediajobs.summary.top.devices.group.title');

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private utilsService: UtilsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private mediaJobsService: MediaJobsService,
    private dialogService: DialogService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.emptyGuid = this.utilsService.EmptyGuid();
  };

  private ngOnInit() {
    this.sceneID = this.route.snapshot.paramMap.get('sceneID');
    this.loadScene();
    this.loadJobsOfScene();
  };

  private loadScene() {
    this.mediaJobsService.GetScene(this.sceneID).subscribe((_data: IMediaJob) => {
      this.scene = _data;
    });
  };

  private loadJobsOfScene() {
    this.mediaJobsService.GetJobsOfScene(this.sceneID).subscribe((_data: IMediaJob[]) => {
      this.mediaJobs = _data;
    });
  };

  public edit() {
    this.dialogEditMediaJob = this.dialog.open(SceneJobsEditSummaryComponent, this.dialogService.getDialogOptionsFullSize());
    this.dialogEditMediaJob.componentInstance.init(this.sceneID);
    this.dialogEditMediaJob.componentInstance.onClose.subscribe(() => {
      this.dialogEditMediaJob.close();
      this.loadJobsOfScene();
    });
  };

  public delete(_mediaJobID: string) {
    this.mediaJobID = _mediaJobID;
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('scene-jobs.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') { return; }

      this.mediaJobsService.RemoveJobFromScene(this.sceneID, _mediaJobID).subscribe(_result => {
        var result = _result as IReturnState;
        if (result.success) {
          this.loadJobsOfScene();
          this.snackBarRef = this.snackBar.open(application.getRawText('scene-jobs.summary.deleted.msg'), application.getRawText('common.close.msg'));
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });

    });
  };

  public getMediaUrlPath(_mediaActivityUrl: string) {
    return evision5.buildApi(this.baseUrl, 'files/thumbnail?mediaurl=' + encodeURIComponent(_mediaActivityUrl));
  };

}
