import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorService } from './httperror.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router,
    private httpErrorService: HttpErrorService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        tap(
          event => {
            //event instanceof HttpResponse ? 'succeeded' : ''
            if (event instanceof HttpErrorResponse && event.status === 401) {
              this.httpErrorService.http401.next(true);
            }
          },
          error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.httpErrorService.http401.next(true);
            }
          })
      );
    //.pipe(catchError((err: HttpErrorResponse) => {
    //  if (err instanceof HttpErrorResponse && err.status == 401) {
    //    this.httpErrorService.http401.next(true);
    //    //this.router.navigate(['login'], { queryParams: { returnUrl: request.url } });
    //    return of(err as any);
    //  }
    //  throw err;
    //}));
  }
}
