import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaDevice } from '../../core/interfaces/IMediaDevice';
import { IMediaJobWithGrouping } from '../../core/interfaces/IMediaJobWithGrouping';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../../app-pwp/src/app/core/services/common/datetime.service';
import { UtilsService } from '../../../../../app-pwp/src/app/core/services/common/utils.service';
import { MediaJobsService } from '../../core/services/data/mediajobs.service';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { SceneSingleComponent } from '../../scenes/scene-single/scene-single.component';
import { IMediaLayout } from '../../core/interfaces/IMediaLayout';
import { MediaLayoutsService } from '../../core/services/data/medialayouts.service';
import { MediaLayoutSingleComponent } from '../medialayout-single/medialayout-single.component';
import { DialogService } from '../../../../../app-pwp/src/app/core/services/common/dialog.service';

@Component({
  selector: 'app-medialayouts-summary',
  templateUrl: './medialayouts-summary.component.html',
  styleUrls: ['./medialayouts-summary.component.css']
})

export class MediaLayoutsSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogMediaLayoutEdit: MatDialogRef<MediaLayoutSingleComponent>;

  private emptyGuid: string = null;

  private displayedColumnsAll: string[] = ['name', 'gridSize', 'pane3OnTop', 'transitionMode', 'mediaDeviceId01Name', 'mediaDeviceId02Name', 'mediaDeviceId03Name', 'mediaDeviceId04Name', 'edit'];
  public displayedColumns: string[] = this.displayedColumnsAll;

  private filterArchived: boolean = false;

  private mediaLayoutID: string = null;
  public medialayouts: IMediaLayout[] = null;

  public textDeviceFilter: string = application.getRawText('mediajobs.summary.top.devices.title');
  public textDeviceDevice: string = application.getRawText('mediajobs.summary.top.devices.device.title');
  public textDeviceGroup: string = application.getRawText('mediajobs.summary.top.devices.group.title');

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private utilsService: UtilsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private mediaLayoutsService: MediaLayoutsService,
    private dialogService: DialogService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.emptyGuid = this.utilsService.EmptyGuid();
  };

  private ngOnInit() {
    this.loadMediaLayouts();
  };

  private loadMediaLayouts() {
    this.mediaLayoutsService.LoadAll().subscribe((_data: IMediaLayout[]) => {
      this.medialayouts = _data;
    });
  };

  public edit(_mediaLayoutID: string) {
    this.mediaLayoutID = _mediaLayoutID;
    this.dialogMediaLayoutEdit = this.dialog.open(MediaLayoutSingleComponent, this.dialogService.getDialogOptionsOneColumn('95%'));
    this.dialogMediaLayoutEdit.componentInstance.init(_mediaLayoutID);
    this.dialogMediaLayoutEdit.componentInstance.onClose.subscribe(() => {
      this.dialogMediaLayoutEdit.close();
      this.loadMediaLayouts();
    });
  };

  public delete(_mediaLayoutID: string) {
    this.mediaLayoutID = _mediaLayoutID;
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('medialayouts.summary.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.mediaLayoutsService.Delete(_mediaLayoutID).subscribe(_result => {
        var result = _result as IReturnState;
        if (result.success) {
          this.loadMediaLayouts();
          this.snackBarRef = this.snackBar.open(application.getRawText('medialayouts.summary.deleted.msg'), application.getRawText('common.close.msg'));
        }
        else {
          this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
        }
      });

    });
  };

}
