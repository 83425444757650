import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DialogService {
  constructor() { }

  getDialogOptions(height: string, width: string) {
    return {
      autoFocus: true,
      height: height,
      width: width,
      maxWidth: '95vw',
      maxHeight: '95vw'
    };
  };

  getDialogOptionsOneColumn(height: string = '450px') {
    return {
      autoFocus: true,
      height: height,
      width: '550px',
      maxWidth: '95vw',
      maxHeight: '95vw'
    };
  };


  getDialogOptionsOneColumnDialog(height: string = '250px') {
    return {
      autoFocus: true,
      height: height,
      width: '550px',
      maxWidth: '95vw',
      maxHeight: '95vw'
    };
  };

  getDialogOptionsOneColumnAndAHalf(height: string = '450px') {
    return {
      autoFocus: true,
      height: height,
      width: '800px',
      maxWidth: '95vw',
      maxHeight: '95vw'
    };
  };

  getDialogOptionsFullSize() {
    return {
      autoFocus: true,
      height: '95%',
      width: '95%',
      maxWidth: '95vw',
      maxHeight: '95vw'
    };
  };
}
