import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaJob } from '../../core/interfaces/IMediaJob';
import { IMediaDevice } from '../../core/interfaces/IMediaDevice';
import { application } from '../../../../../app-pwp/src/app/core/globals';
import { SecurityService } from '../../../../../app-pwp/src/app/core/services/common/security.service';
import { DateTimeService } from '../../../../../app-pwp/src/app/core/services/common/datetime.service';
import { MediaJobsService } from '../../core/services/data/mediajobs.service';
import { evision5 } from '../../core/globals';
import { IReturnState } from '../../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { SceneSingleComponent } from '../scene-single/scene-single.component';
import { IMediaJobSelectable } from '../../core/interfaces/IMediaJobSelectable';
import { UtilsService } from '../../../../../app-pwp/src/app/core/services/common/utils.service';
import { IMediaJobWithGrouping } from '../../core/interfaces/IMediaJobWithGrouping';
import { DialogService } from '../../../../../app-pwp/src/app/core/services/common/dialog.service';

@Component({
  selector: 'app-scene-jobs-edit-summary',
  templateUrl: './scene-jobs-edit-summary.component.html',
  styleUrls: ['./scene-jobs-edit-summary.component.css']
})

export class SceneJobsEditSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogEditMediaJob: MatDialogRef<SceneSingleComponent>;

  public filterSearch: string = '';
  private sceneID: string = null;

  private emptyGuid: string = null;

  private displayedColumnsAll: string[] = ['name', 'device', 'medialist', 'edit'];
  public displayedColumns: string[] = this.displayedColumnsAll;

  private devicesDic: object = {};
  private devices: IMediaDevice[] = null;
  private groups: IMediaDevice[] = null;

  private selectedJobs: IMediaJobSelectable[] = [];
  public selectedJobsView: IMediaJobSelectable[] = [];
  public mediaJobsAssigned: IMediaJob[] = null;
  public mediaJobsAll: IMediaJob[] = null;

  public textSearchFilter: string = application.getRawText('scene-jobs-edit.summary.search-filter.title');

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private security: SecurityService,
    private dateTimeService: DateTimeService,
    private utilsService: UtilsService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private mediaJobsService: MediaJobsService,
    private dialogService: DialogService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.emptyGuid = this.utilsService.EmptyGuid();
  };

  private ngOnInit() {
  };

  public init(_sceneID: string) {
    this.sceneID = _sceneID;
    this.loadJobsOfScene();
    this.loadJobs();
  };

  private loadJobsOfScene() {
    this.mediaJobsAssigned = null;
    this.mediaJobsService.GetJobsOfScene(this.sceneID).subscribe((_data: IMediaJob[]) => {
      this.mediaJobsAssigned = _data;
      this.done();
    });
  };

  private loadJobs() {
    this.mediaJobsAll = null;
    this.mediaJobsService.GetJobs(false).subscribe((_data: IMediaJobWithGrouping[]) => {
      this.mediaJobsAll = _data;
      this.done();
    });
  };

  public done() {
    if (this.mediaJobsAll === null || this.mediaJobsAssigned === null) {
      return;
    }

    var dic = {};
    for (var f = 0; f < this.mediaJobsAssigned.length; f++) {
      dic[this.mediaJobsAssigned[f].mediaJobID] = this.mediaJobsAssigned[f];
    }

    for (var i = 0; i < this.mediaJobsAll.length; i++) {
      var mj = this.mediaJobsAll[i];
      var tmp: IMediaJobSelectable = {
        mediaJobID: mj.mediaJobID,
        mediaJobName: mj.jobName,
        deviceName: mj.deviceName,
        mediaListID: mj.mediaListID,
        mediaListName: mj.listName,
        selected: dic[mj.mediaJobID] !== null && dic[mj.mediaJobID] !== undefined,
        mediaListFirstMediaUrl: mj.mediaListFirstMediaUrl
      };
      this.selectedJobs.push(tmp);
    }

    this.selectedJobs.sort((_a, _b) => _a.mediaJobName.localeCompare(_b.mediaJobName));
    this.selectedJobsView = this.selectedJobs;

  };

  /**
   * Performs a search on all items.
   */
  public search() {
    if (this.filterSearch === null || this.filterSearch === undefined || this.filterSearch === '') {
      this.selectedJobsView = this.selectedJobs;
      return;
    }

    var tmp = [];
    for (var i = 0; i < this.selectedJobs.length; i++) {
      var match =
        (this.selectedJobs[i].deviceName !== null && this.selectedJobs[i].deviceName.toLowerCase().indexOf(this.filterSearch.toLowerCase()) >= 0) ||
        (this.selectedJobs[i].mediaJobName !== null && this.selectedJobs[i].mediaJobName.toLowerCase().indexOf(this.filterSearch.toLowerCase()) >= 0) ||
        (this.selectedJobs[i].mediaListName !== null && this.selectedJobs[i].mediaListName.toLowerCase().indexOf(this.filterSearch.toLowerCase()) >= 0)
      if (match) {
        tmp.push(this.selectedJobs[i]);
      }
    }

    this.selectedJobsView = tmp;
  };

  public save() {
    this.mediaJobsService.SaveJobsToScene(this.sceneID, this.selectedJobs).subscribe((_result: IReturnState) => {
      if (_result.success) {
        this.onClose.emit();
      }
      else if (_result.stateID === 99) {
        this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
        this.dialogGeneric.componentInstance.options.actionOk = true;
        this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
        this.dialogGeneric.componentInstance.options.message = application.getRawText('scene-jobs-edit.summary.toolbar.save.failed.multiple-devices.msg');
      }
      else {
        this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    });
  };

  public close() {
    this.onClose.emit();
  };

  public getMediaUrlPath(_mediaActivityUrl: string) {
    return evision5.buildApi(this.baseUrl, 'files/thumbnail?mediaurl=' + encodeURIComponent(_mediaActivityUrl));
  };

}
