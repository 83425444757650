import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { application } from '../core/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageSelectorComponent } from '../image-selector/image-selector.component';

import { UiKeyHandleService, KeyHandler } from '../core/services/common/uikeyhandle.service';
import { SecurityService } from '../core/services/common/security.service';
import { DateTimeService } from '../core/services/common/datetime.service';
import { HttpWorkerFactoryService, HttpWorker } from '../core/services/http/httpworkerfactory.service';
import { BusyIndicatorFactoryService, BusyIndicatorWorker } from '../core/services/busyindicator/busyindicatorfactory.service';
import { ModulesService } from '../core/services/data/modules.service';
import { IModule } from '../core/interfaces/IModule';
import { IReturnState } from '../core/interfaces/IReturnState';
import { ArticlesService } from '../core/services/data/articles.service';
import Article from '../core/models/Article';
import { IArticle } from '../core/interfaces/IArticle';
import { UtilsService } from '../core/services/common/utils.service';
import { DialogService } from '../core/services/common/dialog.service';

@Component({
  selector: 'app-article-single',
  templateUrl: './article-single.component.html',
  styleUrls: ['./article-single.component.css']
})

export class ArticleSingleComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();

  private dialogImageSelect: MatDialogRef<ImageSelectorComponent>;
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public articleID: number = null;
  public article: IArticle = new Article();
  public startTime: string = '00:00';
  public endTime: string = '00:00';
  public htmlText: string = null;

  public modules: IModule[] = [];

  private initiated: boolean = false;

  public textTitle: string = application.getRawText('pwp.article.single.form.title.title');
  public textShortDescription: string = application.getRawText('pwp.article.single.form.shortdescription.title');
  public textModule: string = application.getRawText('pwp.article.single.form.module.title');
  public textStartDate: string = application.getRawText('pwp.article.single.form.startdate.title');
  public textEndDate: string = application.getRawText('pwp.article.single.form.enddate.title');
  public textRedirectUrl: string = application.getRawText('pwp.article.single.form.redirecturl.title');
  public textRedirectType: string = application.getRawText('pwp.article.single.form.redirecttype.title');

  //dialogGeneric: MatDialogRef<DialogComponent>;

  public keyHandler: KeyHandler;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private uiKeyHandleService: UiKeyHandleService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dateTimeService: DateTimeService,
    private utilsService: UtilsService,
    private modulesService: ModulesService,
    private articleService: ArticlesService,
    private dialogService: DialogService) {
    //this.security.checkForRolesByName('devices.summary');
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.keyHandler = this.uiKeyHandleService.GetKeyHandler();
    this.keyHandler.OnSave.subscribe(() => this.save());
  };

  private ngOnInit() {
    if (this.initiated) {
      return;
    }

    var passedIdString = this.route.snapshot.paramMap.get('id');
    var tmpID = passedIdString === 'new'
      ? 0
      : parseInt(passedIdString);
    this.articleID = tmpID > 0 ? tmpID : null;
    this.initArticle();
  };

  public initArticle() {
    if (this.initiated) {
      return;
    }

    this.loadModules();

    if (!(this.articleID > 0)) {
      this.articleID = null;
      this.article = new Article();
      this.article.id = 0;
      this.article.redirectType = 0;
      this.article.startDate = this.dateTimeService.Now();
      this.article.endDate = this.dateTimeService.Now();

      this.startTime = this.dateTimeService.GetTime(this.article.startDate, true);
      this.endTime = this.dateTimeService.GetTime(this.article.endDate, true);
    }
    else {
      this.loadArticle();
    }

    this.initiated = true;
  };

  private loadArticle() {
    this.articleService.GetArticle(this.articleID).subscribe(result => {
      this.article = result as IArticle;

      this.setHtml();

      var tmpStartDate = this.dateTimeService.EnsureUTC(this.article.startDate);
      this.startTime = this.dateTimeService.GetTimeOrDefault(tmpStartDate, true);
      this.article.startDate = this.dateTimeService.TimeToLunch(tmpStartDate);

      var tmpEndDate = this.dateTimeService.EnsureUTC(this.article.endDate);
      this.endTime = this.dateTimeService.GetTimeOrDefault(tmpEndDate, true);
      this.article.endDate = this.dateTimeService.TimeToLunch(tmpEndDate);

    }, error => console.error(error));
  };

  private loadModules() {
    this.modulesService.GetArticles().subscribe((_modules) => {
      this.modules = _modules;
      if (!(this.articleID > 0) && this.modules.length > 0) {
        this.article.moduleID = this.modules[0].moduleID;
      }
    });
  };

  public startFromNow() {
    var tmp = this.dateTimeService.Now();
    this.startTime = this.dateTimeService.GetTimeOrDefault(tmp, true);
    this.article.startDate = this.dateTimeService.TimeToLunchOrDefault(tmp);
  };

  public endToNow() {
    var tmp = this.dateTimeService.Now();
    this.endTime = this.dateTimeService.GetTimeOrDefault(tmp, true);
    this.article.endDate = this.dateTimeService.TimeToLunchOrDefault(tmp);
  };

  public save() {
    this.getHtml();
    this.article.startDate = this.dateTimeService.SetTimeToDate(this.startTime, this.article.startDate);
    this.article.endDate = this.dateTimeService.SetTimeToDate(this.endTime, this.article.endDate);

    this.articleService.SaveArticle(this.article).subscribe(result => {
      if (result.success) {
        this.article = result.data as IArticle;
        this.articleID = this.article.id;
        this.snackBarRef = this.snackBar.open(application.getRawText('common.saved.msg'), application.getRawText('common.close.msg'));

        this.close();
      }
      else {
        this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
      }
    },
      error => console.error(error));
  };

  private setHtml() {
    if (this.article !== null && this.article.text !== null) {
      this.htmlText = this.utilsService.UnescapeHtml(this.article.text);
    }
  };

  private getHtml() {
    if (this.article !== null && this.htmlText !== null) {
      this.article.text = this.utilsService.EscapeHtml(this.htmlText);
    }
  };

  public openSelectImage() {
    this.dialogImageSelect = this.dialog.open(ImageSelectorComponent, this.dialogService.getDialogOptionsOneColumnAndAHalf());
    this.dialogImageSelect.componentInstance.Init();
    this.dialogImageSelect.componentInstance.ModuleID = this.article.moduleID;
    this.dialogImageSelect.componentInstance.OnClose.subscribe(() => {
      if (this.dialogImageSelect.componentInstance.ImageItem !== null) {
        this.article.imageID = this.dialogImageSelect.componentInstance.ImageItem.id;
      }

      if (this.dialogImageSelect.componentInstance.ThumbItem !== null) {
        this.article.thumbID = this.dialogImageSelect.componentInstance.ThumbItem.id;
      }

      this.dialogImageSelect.close();
    });
  };

  public close() {
    this.onClose.emit(this.article.id);
    this.router.navigate(['pwp', 'articles']);
  };

  public release(_released: boolean) {
    this.article.released = _released;
  };

  public onStartDateEnabledChanged() {
    if (this.article.startDate === null) {
      this.startFromNow();
    }
    else {
      this.article.startDate = null;
      this.startTime = '12:00';
    }
  };

  public onEndDateEnabledChanged() {
    if (this.article.endDate === null) {
      this.endToNow();
    }
    else {
      this.article.endDate = null;
      this.endTime = '12:00';
    }
  };

}
