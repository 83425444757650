import { Component, Inject, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaDevicesStartuptimeComponent } from '../mediadevices-startuptime/mediadevices-startuptime.component';
import { MediaDeviceSingleComponent } from '../mediadevice-single/mediadevice-single.component';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaDevice } from '../core/interfaces/IMediaDevice';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { DialogService } from '../../../../app-pwp/src/app/core/services/common/dialog.service';

@Component({
  selector: 'app-mediadevices-summary',
  templateUrl: './mediadevices-summary.component.html',
  styleUrls: ['./mediadevices-summary.component.css']
})

export class MediaDevicesSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogDuration: MatDialogRef<MediaDevicesStartuptimeComponent>;
  private dialogNewMediaDevice: MatDialogRef<MediaDeviceSingleComponent>;

  public displayedColumns: string[] = ['state', 'deviceName', 'machineName', 'deviceIp', 'deviceMac', 'edit'];

  private mediaDeviceID: string = null;
  public mediaDevices: IMediaDevice[];
  private mediaNonVirtualDevices: IMediaDevice[];
  private mediaDeviceGroups: IMediaDevice[];

  // runningMode: normal, dashboard
  public MODE_DASHBOARD: string = 'dashboard';
  public MODE_NORMAL: string = 'normal';
  @Input() public runningMode: string = this.MODE_NORMAL;

  // viewmode: data, remoteview
  private refreshRemoteViewIntervalId = null;
  private remoteView: boolean = false;
  public readonly VIEW_MODE_DATA = 'data';
  public readonly VIEW_MODE_REMOTEVIEW = 'remoteview';
  public viewMode: string = this.VIEW_MODE_DATA;

  // groupmode: all, withoutgroup, group
  private groupMode: string = 'all';
  private selectedGroup: string = null;

  public mediaDevicePreviewBig: IMediaDevice = null;

  private preSelectedGroupID: string = null;

  public RemoteViewPaths: any = {};
  public RemoteViewImages: any = {};

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private security: SecurityService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dialogService: DialogService) {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    //this.runningMode = this.MODE_DASHBOARD;
  };

  public ngOnInit() {
    if (!this.security.CheckForRoles(true, ['contentmanager'])) {
      return;
    }

    this.preSelectedGroupID = this.route.snapshot.paramMap.get('groupdID');

    if (this.preSelectedGroupID === null) {
      this.loadMediaDevices();
    }

    this.loadMediaDeviceGroups();

    if (this.runningMode === this.MODE_DASHBOARD) {
      this.remoteView = true;
      this.onRemoteViewChangeHandler();
    }

    this.refreshRemoteViews();
  };

  public ngOnDestroy() {
    if (this.refreshRemoteViewIntervalId) {
      // Clear to avoid that remoteview is continue running in background.
      clearInterval(this.refreshRemoteViewIntervalId);
    }
  }

  private getRemoteViewPath(_mediaDeviceID: string) {
    var imageUrl = evision5.buildApi(this.baseUrl, 'monitoring/mediadevice/' + _mediaDeviceID + '/screencapture') + '?ts=' + Date.now() + '&format=base64';
    return imageUrl;
  };

  public onRemoteViewChangeHandler() {
    this.viewMode = this.remoteView === true
      ? this.VIEW_MODE_REMOTEVIEW
      : this.VIEW_MODE_DATA;
  };

  public onGroupSelectedHandler(_groupID: string) {
    if (['all', 'withoutgroup', 'new'].indexOf(_groupID) >= 0) {
      if (_groupID === 'new') {
        this.newGroup('new');
      }
      else {
        this.groupMode = _groupID;
        this.selectedGroup = null;
        this.loadMediaDevices();
      }
    }
    else {
      this.selectedGroup = _groupID;
      this.groupMode = 'group';
      this.loadMediaDevices();
    }
  };

  private loadMediaDevices() {
    this.mediaDevices = [];
    var url = '';
    if (this.groupMode === 'all') {
      url = evision5.buildApi(this.baseUrl, 'mediadevices');
    }
    else if (this.groupMode === 'withoutgroup') {
      url = evision5.buildApi(this.baseUrl, 'mediadevices/deviceswithoutgroup');
    }
    else if (this.groupMode === 'group') {
      url = evision5.buildApi(this.baseUrl, 'mediadevices/group/' + this.selectedGroup);
    }

    this.http.get<IReturnState>(url)
      .subscribe(result => {
        this.mediaDevices = result.data as IMediaDevice[];
        this.mediaNonVirtualDevices = [];

        for (var i = 0; i < this.mediaDevices.length; i++) {
          this.getState(this.mediaDevices[i]);
          if (!this.mediaDevices[i].virtualDevice) {
            this.mediaNonVirtualDevices.push(this.mediaDevices[i]);
          }
        }

      }, error => console.error(error));
  };

  public loadMediaDeviceGroups() {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevices/groups'))
      .subscribe(result => {
        this.mediaDeviceGroups = result.data as IMediaDevice[];

        if (this.preSelectedGroupID !== null) {
          this.onGroupSelectedHandler(this.preSelectedGroupID);
          this.preSelectedGroupID = null;
        }

      }, error => console.error(error));
  };

  /**
   * Sets the remoteViewPath with a new timestamp-queryString to enforce reloading.
   * This is done in an recurring interval.
   */
  public refreshRemoteViews() {
    this.refreshRemoteViewIntervalId = setInterval(() => {
      if (this.mediaDevices === null || this.mediaDevices === undefined || this.mediaDevices.length === 0) {
        return;
      }

      if (!this.remoteView || this.viewMode !== this.VIEW_MODE_REMOTEVIEW) {
        return;
      }

      for (var i = 0; i < this.mediaDevices.length; i++) {
        if (!this.mediaDevices[i]) {
          continue;
        }
        let mediaDevice = this.mediaDevices[i];
        let path = this.getRemoteViewPath(mediaDevice.mediaDeviceID);
        this.http.get<string>(path)
          .subscribe((result: any) => {

            if (result && result.image) {
              this.RemoteViewImages[mediaDevice.mediaDeviceID] = result.image;
            }
            else {
              this.RemoteViewImages[mediaDevice.mediaDeviceID] = null;
            }

          });
      }

    }, 5000);
  };

  public getState(_element: IMediaDevice) {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + _element.mediaDeviceID + '/state'))
      .subscribe(result => {
        var state = result.data as unknown as number;
        _element.state = state;
      }, error => console.error(error));
  };

  public newGroup(_groupID: string) {
    this.dialogNewMediaDevice = this.dialog.open(MediaDeviceSingleComponent, this.dialogService.getDialogOptionsOneColumn('95%'));
    this.dialogNewMediaDevice.componentInstance.asGroup();
    this.dialogNewMediaDevice.componentInstance.init(_groupID);
    this.dialogNewMediaDevice.componentInstance.onClose.subscribe(() => {
      this.dialogNewMediaDevice.close();

      this.selectedGroup = this.dialogNewMediaDevice.componentInstance.mediaDeviceID;
      this.groupMode = 'group';

      this.loadMediaDeviceGroups();
      this.loadMediaDevices();
    });
  };

  public editDevice(_deviceID: string) {
    this.mediaDeviceID = _deviceID;
    this.dialogNewMediaDevice = this.dialog.open(MediaDeviceSingleComponent, this.dialogService.getDialogOptionsOneColumn('95%'));
    this.dialogNewMediaDevice.componentInstance.init(_deviceID);
    if (this.groupMode === 'group') {
      this.dialogNewMediaDevice.componentInstance.mediaDeviceGroupID = this.selectedGroup;
    }
    this.dialogNewMediaDevice.componentInstance.asDevice();
    this.dialogNewMediaDevice.componentInstance.onClose.subscribe(() => {
      this.dialogNewMediaDevice.close();
      this.loadMediaDeviceGroups();
      this.loadMediaDevices();
    });
  }

  public deleteGroup(_groupID: string) {
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('mediadevices.summary.devices.delete.group.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.http
        .delete<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + _groupID), {})
        .subscribe(
          _result => {
            var result = _result as IReturnState;
            if (result.success) {
              this.loadMediaDeviceGroups();
              this.onGroupSelectedHandler('all');
              this.snackBarRef = this.snackBar.open(application.getRawText('mediadevices.summary.devices.deleted.group.msg'), application.getRawText('common.close.msg'));
            }
            else {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    });
  };

  public deleteMediaDevice(_mediaDeviceID: string) {
    this.mediaDeviceID = _mediaDeviceID;
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('mediadevices.summary.devices.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.http
        .delete<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevice/' + _mediaDeviceID), {})
        .subscribe(
          _result => {
            var result = _result as IReturnState;
            if (result.success) {
              this.loadMediaDevices();
              this.snackBarRef = this.snackBar.open(application.getRawText('mediadevices.summary.devices.deleted.msg'), application.getRawText('common.close.msg'));
            }
            else {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    });
  };

  public setStartupTime() {
    var _this = this;
    this.dialogDuration = this.dialog.open(MediaDevicesStartuptimeComponent, this.dialogService.getDialogOptionsOneColumn());
    this.dialogDuration.componentInstance.onSaved.subscribe(function () {
      _this.dialogDuration.close();
      _this.loadMediaDevices();
    });
  };

  public openPreview(_element: IMediaDevice) {
    window.open(_element.previewUrl);
  };

  public openRemoteViewPreview(_element: IMediaDevice) {
    this.mediaDevicePreviewBig = _element;
  };

  public closeRemoteViewPreviewBig() {
    this.mediaDevicePreviewBig = null;
  };
}
