import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MediaDeviceStartupExceptionSingleComponent } from '../mediadevicestartupexception-single/mediadevicestartupexception-single.component';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaDeviceStartupException } from '../core/interfaces/IMediaDeviceStartupException';
import { DateTimeService } from '../../../../app-pwp/src/app/core/services/common/datetime.service';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { evision5 } from '../core/globals';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { DialogService } from '../../../../app-pwp/src/app/core/services/common/dialog.service';

@Component({
  selector: 'app-mediadevicestartupexception-summary',
  templateUrl: './mediadevicestartupexception-summary.component.html',
  styleUrls: ['./mediadevicestartupexception-summary.component.css']
})

export class MediaDeviceStartupExceptionSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogException: MatDialogRef<MediaDeviceStartupExceptionSingleComponent>;

  public displayedColumns: string[] = ['name', 'from', 'to', 'edit'];

  private id: string = null;
  public mediaExceptions: IMediaDeviceStartupException[];

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private dateTimeService: DateTimeService,
    private security: SecurityService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private dialogService: DialogService) {
    //this.security.checkForRolesByName('devices.summary');

    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.loadExceptions();
  };

  private loadExceptions() {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevicestartupexceptions'))
      .subscribe(result => {
        this.mediaExceptions = result.data as IMediaDeviceStartupException[];
        this.mediaExceptions.forEach((_me) => {
          _me.endDate = this.dateTimeService.EnsureUTC(_me.endDate);
          _me.startDate = this.dateTimeService.EnsureUTC(_me.startDate);
        });
      }, error => console.error(error));
  };

  public editException(_exceptionID: string) {
    this.id = _exceptionID;
    this.dialogException = this.dialog.open(MediaDeviceStartupExceptionSingleComponent, this.dialogService.getDialogOptionsOneColumn());
    this.dialogException.componentInstance.init(_exceptionID);
    this.dialogException.componentInstance.onClose.subscribe(() => {
      this.dialogException.close();
      this.loadExceptions();
    });
  };

  public deleteException(_exceptionID: string) {
    this.id = _exceptionID;
    this.dialogGeneric = this.dialog.open(DialogComponent, this.dialogService.getDialogOptionsOneColumnDialog());
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('mediadevicestartupexceptions.summary.exception.delete.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.http
        .delete<IReturnState>(evision5.buildApi(this.baseUrl, 'mediadevicestartupexception/' + _exceptionID), {})
        .subscribe(
          _result => {
            var result = _result as IReturnState;
            if (result.success) {
              this.loadExceptions();
              this.snackBarRef = this.snackBar.open(application.getRawText('mediadevicestartupexceptions.summary.exception.deleted.msg'), application.getRawText('common.close.msg'));
            }
            else {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    });
  };

}
